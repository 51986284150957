@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
$pad-mag-zero: 0px 0px 0px 0px;
$marg_15_side_LR: 0px 0px 0px 0px;
$global-font-size-1: 20px;
$global-font-size-2: 12px;
$global-card-height: 120px;
$global-text-align: justify;
$global-font-style-1: 'Helvetica Neue', Helvetica, sans-serif;
$global-font-style-2: Arvo , Vollkorn, Ubuntu;
$global-font-style-3: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;

/* start mixin */

/* centered columns styles */
.row-centered {
  text-align:center;
}
.col-centered {
  display:inline-block;
  float:none;
  /* reset the text-align */
  text-align:left;
  /* inline-block space fix */
  margin-right:-4px;
}
.col-fixed {
  /* custom width */
  width:320px;
}
.col-min {
  /* custom min width */
  min-width:320px;
}
.col-max {
  /* custom max width */
  max-width:320px;
}


.panga-card-height{
  height:120px;
}
.panga-margin-tb-10{
  margin-top: 10px;
  margin-bottom: 10px;
}

.panga-margin-tb-5{
  margin-top: 5px;
  margin-bottom: 5px;
}


.panga-margin-zero{
  margin: $pad-mag-zero;
}
.panga-padding-zero{
  padding:$pad-mag-zero;
}
.panga-20-top-padding {
  padding-top: 20px;
  padding-bottom: 20px;
}
.panga-padding-tb-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.panga-no-padding {
  margin: $pad-mag-zero;
  padding: $pad-mag-zero;
}
.panga-pad-mag-zero {
  margin: $pad-mag-zero;
  padding: $pad-mag-zero;
}
.panga-pad-0-mag-10{
  margin:10px 0px 10px 0px;
  padding: $pad-mag-zero;
}
.panga-pad-0-mag-5{
  margin:5px 0px 5px 0px;
  padding: $pad-mag-zero;
}
.panga-pad-5-mag-10 {
  margin: 5px 0px 5px 0px;
  padding: 5px 5px 5px 5px;
}

.panga-image-fill {
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: inherit;
  //width: inherit;
}
@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// Class
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* end mixin */
body {
  width: 100%;
  height: 100%;
  font-family:  $global-font-style-1;
}

#panga-content-wrapper {
  background: #222222;
  color: #FFFFFF;
  padding-bottom: 10px;
  font-family:  $global-font-style-2;
}

/**********************/
/*upper navigation bar*/
#panga-navbar-logo {

  background: #D8D8D8;

  #panga-logo {
    width: 100px;
  }

  #panga-org-name {
    font-size: 24px;
    font-weight: bolder;
    line-height: 5%;
    vertical-align: middle;
    /*max-width: 10px;*/
  }

  .panga-header-logo-span {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-justify: distribute;
  }


}
/*upper navigation bar*/
/**********************/
/*****************/
/* lower navbar */
#panga-navbar {
  background: #000000;
  margin: $pad-mag-zero;
  padding: $pad-mag-zero;
  border: none;
  border-radius: 0px;


  #panga-navbar-container {
    background: #000000;
    border-width: 0px;
    padding: inherit;

    .navbar-brand{
      display: none;

      color: #FFFFFF;
      background-color: #000000;

    }

    #panga-navbar-menu {
      margin: $pad-mag-zero;
      padding: $pad-mag-zero;
      overflow: hidden;
      background: #000000;
      line-height: 0;
      font-weight: normal;
      font-size: 12pt;
      text-transform: uppercase;
      /* links effect section */
      ul.nav.navbar-nav > {
        li {
          & > a {
            color: #FFFFFF;
            background-color: #000000;

          }

          &.dropdown:hover{
            margin: $pad-mag-zero;
            padding: $pad-mag-zero;
            ul.dropdown-menu {
              display: block;
              margin: -1px 0px 0px -1px;
              padding: 0px 0px 0px 0px;

              li{


                text-align: left;
                vertical-align: middle;
                & > a {
                  vertical-align: middle;
                  color: #FFFFFF;
                  background-color: #000000;
                  height: 40px;
                  line-height: 35px;

                }
                & > a:hover {
                  color: #c6ece7;
                  background-color: #222222;

                }

              }
            }
          }



        }

        .active > a {
          color: #FFFFFF;
          background-color: #222222;
        }
        li > a:hover, .active > a:hover, li > a:focus, .active > a:focus {
          color: #c6ece7;
          background-color: #222222;
        }
      }
    }

  }

}

/*ul.nav li.dropdown:hover > ul.dropdown-menu {
  display: block;
}*/
/** end lower navbar **/
/**********************/

video::-internal-media-controls-download-button {
  display:none;
}

video::-webkit-media-controls-enclosure {
  overflow:hidden;
}

video::-webkit-media-controls-panel {
  width: calc(100% + 30px); /* Adjust as needed */
}
/**********************/
.panga-span-bread-crumb{
  display: inline-block;
  ul.panga-breadcrumb{
    display: inline;
    padding: 0px 0px ;
    li:before { content: '|>'}
    li{
      display: inline-block;
      margin: 0px 5px;
      list-style: none;
      a{
        text-transform: capitalize;
        text-decoration: none;
        color: #FFFFFF;
        font-weight: bold;
        &:hover{
          color: #D6D6D6;
        }
      }
    }
  }
}

.panga-post-media-image {
  height: 300px;
  @extend .panga-image-fill;
}
.panga-post-media-video {

}

.panga-post-media-image-col-lg-8{
  height: 140px;
  @extend .panga-image-fill;
}

.panga-post-media-video-col-lg-8{

}

.panga-post{
  h1.panga-post-title{
    margin-top: 10px;
    margin-bottom: 10px;
    text-transform: capitalize;
  }
  div.panga-post-content{
    font-size: $global-font-size-1;
    text-align: justify;

  }

}



.panga-post-landing{
  h1.panga-post-landing-title{
    margin-top: 10px;
    margin-bottom: 10px;
    text-transform: capitalize;
  }
  div.panga-post-landing-content{
    font-size: $global-font-size-1;
    text-align: center;

  }

}


/*evnt post list */
.panga-posts{
  background-color: #343434;
  @extend .panga-pad-5-mag-10;
  font-family: $global-font-style-2;
  .panga-posts-bg{
    color: #404040;
    list-style-type: none;
    @extend .panga-card-height;
    @extend .panga-pad-mag-zero;
    .panga-posts-img {
        @extend .panga-image-fill;
        height: 120px;

    }
    .panga-posts-info {
      background-color: #CBCBCB;
      font-size: $global-font-size-2;
      @extend .panga-card-height;
      .panga-posts-info-content {
        @extend .panga-pad-mag-zero;
        text-align: justify;
      }
      .panga-posts-info-action {
        @extend .panga-pad-mag-zero;
      }
    }
  }

}
/*post list */
/*sermons post list */
.panga-sermons {
  background-color: #343434;
  @extend .panga-pad-5-mag-10;
  font-family: $global-font-style-2;
  .panga-sermons-bg{
    color: #404040;
    list-style-type: none;
    @extend .panga-pad-mag-zero;
    background-color: #15497c;
    .panga-sermons-date {
      color: #FFFFFF;
    }
    .panga-sermons-info {
      background-color: #CBCBCB;
      font-size: $global-font-size-2;
      .panga-sermons-info-content {
        @extend .panga-pad-mag-zero;
        text-align: justify;
      }
      .panga-sermons-info-action {
        @extend .panga-pad-mag-zero;
      }
    }

  }
}
/*sermons post list */

/*evnt post list */

.panga-events {
  background-color: #343434;
  @extend .panga-pad-5-mag-10;
  .panga-events-bg{
    color: #404040;
    list-style-type: none;
    @extend .panga-pad-mag-zero;
    background-color: #C52C66;
    .panga-events-date {
      color: #FFFFFF;
    }
    .panga-events-info {
      background-color: #CBCBCB;
      font-size: $global-font-size-2;
      .panga-events-info-content {
        @extend .panga-pad-mag-zero;
        text-align: justify;
      }
      .panga-events-info-action {
        @extend .panga-pad-mag-zero;
      }
    }
  }
}
/*event post list */


/* staffs posts list */

/* staffs posts list */
/*
contact page
*/

.panga-contact-form {

  input, textarea, button {
    background-color: #969696;
    color: #FFFFFF;
    border-radius: 0px;
  }

  ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: #FFFFFF;
  }
  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #FFFFFF;
    opacity:  1;
  }
  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #FFFFFF;
    opacity:  1;
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #FFFFFF;
  }
}

#map-overlay {
  background-color: #669de5;
}

.scrolloff {
  pointer-events: none;
}

#panga-google-map {
  width: 100%;
  height: 340px;
}

/*
contact page
*/

/*
* this stuff page
*
*/

.panga-staffs{

  .panga-staffs-container{


    .panga-staffs-wrapper{
      background-color:#f8f8f8;


      @extend .panga-pad-0-mag-5;

      .panga-staffs-img{
        padding: 0px;
        text-decoration: none;
        margin-bottom: 0px;
        a.thumbnail{
          margin-bottom: 0px;
        }

      }

      .panga-staffs-info{

        div.panga-staffs-info-name{
          h5, h4, h3 {
            margin : 5px 0px 5px 0px;
            padding: 0px 0px 0px 0px;
            text-transform: capitalize;
            color: #000000;
          }
        }
        .panga-staffs-info-action{
          text-align: center;
        }

      }
    }

  }
}


/*

*/
.panga-staff-social{
  text-align: center;
  height: 35px;
  display: inline-block;

  .p-s-l {
    display: inline-block;
    width: 35px;
    zoom: 100%;

    color: #ffffff;
  }

  .p-s-l-f {
    background: #3b5998;
    &:hover {
      background: transparent;
      color: #3b5998;
    }
  }

  .p-s-l-t {
    background: #4099FF;
    &:hover {
      background: transparent;
      color: #4099FF;
    }
  }

  .p-s-l-g {
    background: #F90101;
    &:hover {
      background: transparent;
      color: #F90101;
    }
  }

}

/**
 * this stuff page
 *
**/
.panga-staff-item{

  .panga-staff-title{

  }
  .thumbnail{
    margin-bottom: 0px;
  }
  .panga-staff-caption {
    h5,h6{
      color: #000000;
      font-weight: bolder;
    }
  }
  .panga-staff-content{
    font-size: 18px;
  }


}

/**
 * the our team page
 *
**/
.content {
  padding-top: 30px;
}

/* Heading */
.heading {
  z-index: 1;
  position: relative;
  text-align: center;
  margin-bottom: 10px;
}

.heading:after {
  left: 50%;
  height: 3px;
  width: 50px;
  content: " ";
  /*bottom: -35px;*/
  margin-left: -25px;
  position: absolute;
  background: #444;
}

.heading h2 {
  font-size: 40px;
  font-weight: 500;
  /*margin: 0 0 10px;*/
  color: #FFF;
  //color: #444;
}

.heading p {
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #8693a7;
}

/* Team Members */
.team-members {
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  /*margin-bottom: 35px;*/
}

.team-members .team-avatar {
  position: relative;
}

.team-members .team-avatar:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(129, 129, 129, 0.1);
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.team-members .team-avatar img {
  display: block;
  margin: 0 auto;
  text-align: center;
}

.team-members .team-desc {
  left: auto;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  position: absolute;
  opacity: 0;
  color: #fff;
  -webkit-transform: translate3d(0, 10%, 0);
  transform: translate3d(0, 10%, 0);
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -ms-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.team-members .team-desc h4 {
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 10px;
  color: #fff;
}

.team-members .team-desc span {
  display: block;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
}

.team-members:hover .team-avatar:after {
  background: rgba(47, 60, 72, 0.5);
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.team-members:hover .team-desc {
  -webkit-transform: translate3d(0, -5%, 0);
  transform: translate3d(0, -5%, 0);
  -webkit-transform: translate3d(0, -10%, 0);
  transform: translate3d(0, -10%, 0);
}

.team-members:hover .team-desc {
  opacity: 1;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

/*footer division*/

#panga-footer {
  background: #ffcc00;
  font-family: $global-font-style-3;
  font-weight: 600;
  color: #14487C;
  font-size: 15px;

  ul#panga-nav-footer {
    color: #14487C;
    margin: 0;
    padding: 0 1em;
    list-style: none;
    li{
      color: #14487C;
      text-decoration: none;
      padding: 0.0em 0.0em 0.0em 0.0em;
      margin: 0.0em 0.0em 0.0em 0.0em;
      line-height: 75%;

      a{
        color: #14487C;
        text-decoration: none;
        display: inline-block;
        &:first-letter{
          text-transform: capitalize;

        }
        &:hover {
          color: #14487C;
          text-decoration: underline;
        }

      }
      &:before {
        content: "";
        border-color: transparent #14487C;
        border-style: solid;
        border-width: 0.35em 0 0.35em 0.45em;
        display: block;
        height: 0;
        width: 0;
        left: -1em;
        top: 0.7em;
        position: relative;
      }

    }

  }

  .panga-footer-div{
    h4 {
      color: #14487C;
      text-decoration: underline;
      font-weight: bolder;

      &:first-child{
        text-transform: capitalize;
      }

    }
    &:last-child{
      padding-left: 80px;
    }

  }
  .fill-blue {
    padding: 2px 2px 2px 2px;
    background-color: #14487C;
    display: inline-block;
    width: 50px;
    vertical-align: middle;
    color: #ffffff;
  }

  #panga-social-footer a {
    margin: 5px 0px 5px 0px;
    color: #FFFFFF;
    text-decoration: none;
  }

  .panga-contact-detail {
    display: block;
    font-weight: bold;
    color: #14487C;
    width: 100%;
  }

  /* Skew the container one way */

  .skew-neg {
    -webkit-transform: skew(-30deg);
    -moz-transform: skew(-30deg);
    -o-transform: skew(-30deg);
    tranform: skew(-30deg);
  }

  .text-skew-neg {
    -webkit-transform: skew(30deg);
    -moz-transform: skew(30deg);
    -o-transform: skew(30deg);
    tranform: skew(30deg);
  }



  .panga-date-footer {
    color: #15497c;
    font-weight: bold;
    text-transform: capitalize;

  }





}



/*side bar menu*/



.panga-sidebar{
  color: #d8d8d8;
  h3{

  }
  ul{
    padding-left: 0px;
    li {
      list-style: none;
      border-left: 5px solid #c7dced;
      background-color: #CBCBCB;
      color: #404040;
      padding-left: 5px;
      margin-left: 0px;
      @extend .panga-margin-tb-5;
      a {
        span:first-child {
          font-size: 16px;
          font-weight: bold;
        }
        color: #404040;
        text-transform: capitalize;
        text-decoration:none;
        &:hover {
          color: #808080;
        }

      }
    }
  }
}


.panga-sidebar.panga-sidebar-categories{
  color: #d8d8d8;
  h3{
    padding-top: 20px;
  }
}
.panga-sidebar.panga-sidebar-articles{
  color: #d8d8d8;
  h3{
    padding-top: 10px;
  }
}

.panga-sidebar.panga-sidebar-staffs{
  color: #d8d8d8;
  h3{
    padding-top: 10px;
  }
}

.panga-cat-sidebar{

  .panga-cat-sidebar-ul{
    list-style-type: none;
    li{
      padding-top:5px;
      padding-bottom: 5px;
      &:last-child{
        border-bottom: 2px solid #c7dced;
      }
    }
    a.panga-cat-sidebar-item{
      display: inline-block;
      font-size: 20px;
      text-decoration: none;
      color: #c7dced;
      &:first-letter{
        text-transform: capitalize;
      }

    }
  }
}
/* side bar menu */

/* contact page */
.panga-form-row{

  @extend .panga-margin-tb-5;

  .panga-contact-form {
    input, textarea, button{
      background-color: #969696;
      color: #FFFFFF;
      border-radius: 0px;
    }

  }

}
.panga-org-address{
  font-size: 16px;
  h1{
    text-transform: capitalize;
  }
}

#map-overlay {

  background-color:#669de5;
}

.scrolloff {
  pointer-events: none;
}
#panga-google-map{
  width: 100%;
  height: 340px;

}
/*
contact page
*/






/*sticky menu*/

.sticky {
  position: fixed;
  top: 0;
  opacity: 0.1;
}

/*page 404*/

#rhu_error {
  display: block;
  height: 100%;
  min-height: 100%;
}

.error-template {
  padding: 40px 15px;
  text-align: center;
}

span {
  &.error-number {
    display: block;
    font-size: 122px;
    padding: 0px 0px;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  }
  &.error-message {
    display: block;
    font-size: 72px;
  }
  &.error-details {
    text-align: justify;
    font-size: 25px;
  }
}

.error-actions {
  margin-top: 15px;
  margin-bottom: 15px;
  .btn {
    margin-right: 10px;
  }
}
/*page 404*/

/*galery picture*/

.hovereffect {
  width: 100%;
  height: 100%;
  float: left;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: default;
}

.hovereffect .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
}

.hovereffect img {
  display: block;
  position: relative;
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}

.hovereffect:hover img {
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0 0 0 1 0" /><feGaussianBlur stdDeviation="3" /></filter></svg>#filter');
  filter: grayscale(1) blur(3px);
  -webkit-filter: grayscale(1) blur(3px);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

.hovereffect h2 {
  text-transform: uppercase;
  text-align: center;
  position: relative;
  font-size: 17px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.6);
}

.hovereffect a.info {
  display: inline-block;
  text-decoration: none;
  padding: 7px 14px;
  border: 1px solid #fff;
  margin: 50px 0 0 0;
  background-color: transparent;
}

.hovereffect a.info:hover {
  box-shadow: 0 0 5px #fff;
}

.hovereffect a.info, .hovereffect h2 {
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
  opacity: 0;
  filter: alpha(opacity=0);
  color: #fff;
  text-transform: uppercase;
}

.hovereffect:hover a.info, .hovereffect:hover h2 {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.page-header{

   margin: 10px 0 10px 0;
  text-transform: capitalize;
}

/*galery picture*/


/**************/

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 0px 0;
  border-radius: 4px;
}


/*carousel*/

.panga-carousel{

  .panga-slide{
    @extend .panga-image-fill;
    height:350px;
  //  border: 2px solid #FFFFFF;
    .panga-carousel-caption{
    //  border: 2px solid yellowgreen;
      h1{
        &:first-letter{
          text-transform: uppercase;
        }
        display: inline-block;
        margin-top: 16%;
        padding-left: 10px;
        padding-right: 10px;
        background-color: rgba(34, 34, 34, 0.5);

      }
      div{
        &:first-letter{
          text-transform: uppercase;
        }
        padding-right: 10px;
        padding-left: 10px;
        background-color: rgba(34, 34, 34, 0.5);
        font-size: 16px;
        font-weight: bolder;

        p{
          a{
            &:first-letter{
              text-transform: uppercase;
            }
            &:hover{
              text-decoration:  none;
            }
            color: #FFFFFF;
            text-decoration: underline;
          }
        }
      }

    }

  }



  .slick-prev {
    left: 0px;
    color: #FFFFFF; /*to notice it, is white*/
    z-index: 1;
    height: 100%;
  }
  .slick-next {
    right: 0px;
    color: #FFFFFF; /*to notice it, is white*/
    height: 100%;
  }


  ul.slick-dots{
      bottom: 0px;
      li{

        button{
        }
        button:before{
          font-size: 20px;
          line-height: 20px;
          color:#FFFFFF;
        }
      }
    }



/*  .slick-dots {
    height: 15px;
    width: 15px;
    background: lightgray;
    border-radius: 50px;
    display: inline-block;
    box-shadow:0px 0px 4px gray inset;
  }

  .slick-dots.active {
    background-color: #41ABE5;
    box-shadow: inset 0px 0px 4px #888888;
  }*/

}
/*.carousel-inner{

  height:340px;

}
.carousel-caption h1{
  font-weight: bold;

}
.carousel-caption a{
  font-weight: bold;
  text-decoration: none;
  color: #fff;
}
.carousel-caption a:hover {
  font-weight: bold;
  text-decoration: none;
  color: #E6E6E6;
}

.carousel-caption{
  right: 15%;
  left: 15%;
  text-align: left;
}
.carousel-caption h3,.carousel-caption div {
  background-color: rgba(34, 34, 34, 0.5);
  padding-top: 5px;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 10px;

}

.carousel-caption h3{
  display: inline-block;
  margin-bottom: 0px;
}
.carousel-indicators div {

}

.carousel-caption h3:first-letter{
  text-transform: uppercase;
}

.carousel-caption p{
  font-size:20px;
}

.carousel-indicators li {
  display: inline-block;
  width: 40px;
  background-color: rgba(34, 34, 34, 0.5);
}
.carousel-indicators .active {
  width: 40px;
  background-color: #222222;
}*/
/*carousel*/
